import i18n from "@/i18n";
import { createRouter, createWebHistory } from "vue-router";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import store from "../store/index";
import routes from "./routes";

const useBlesta = process.env.VUE_APP_USE_BLESTA === "true" ? true : false;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  // parse token if exists
  if (!store.getters["auth/hasUserId"] && store.getters["auth/getAccessToken"]) {
    await store.dispatch("auth/parseAccessToken", {
      token: store.getters["auth/getAccessToken"],
    });
  }

  if (store.getters["auth/isAdmin"] && store.getters["auth/getAccessToken"]) {
    window.location = "/admin/dashboard";

    return false;
  }

  if (
    to.name === "login" &&
    store.getters["auth/hasUserId"] &&
    store.getters["auth/getAccessToken"]
  ) {
    router.push({ name: "dashboard_user" });
  }

  // redirect to login if unauthorized
  if (to.meta.requiresAuth && !store.getters["auth/hasUserId"]) {
    return next("/login");
  }

  // redirect to login from order page if authorized
  if (to.name === "order" && store.getters["auth/hasUserId"]) {
    return next("/login");
  }

  if (to.meta.blesta !== undefined && !useBlesta) {
    return next({ name: "dashboard_user" });
  }

  // redirect to dashboard if does not permission
  if (to.meta.role !== undefined) {
    let accessRole = store.getters["auth/hasRole"](to.meta.role);
    let accessToPage = true;
    let accessToAdditionalRole = true;

    if (Array.isArray(to.meta.role)) {
      accessRole = to.meta.role.some((role) => {
        if (store.getters["auth/hasRole"](role)) {
          return true;
        }

        return false;
      });
    }

    if (to.meta.access !== undefined) {
      accessToPage = to.meta.access.every(
        (access) => store.getters[`auth/${access}`]
      );
    }

    if (to.meta.additionalRole !== undefined) {
      let hasAdditionalUserRole = store.getters["auth/hasAdditionalUserRole"];

      if (to.meta.additionalRole === false) {
        accessToAdditionalRole = !hasAdditionalUserRole;
      }

      if (to.meta.additionalRole !== false && hasAdditionalUserRole) {
        accessToAdditionalRole = to.meta.additionalRole.some((role) =>
          store.getters["auth/hasRole"](role)
        );
      }
    }

    if (accessRole && accessToPage && accessToAdditionalRole) {
      return next();
    }

    setTimeout(() => {
      toast(i18n.global.t("error.access_denied"), {
        theme: 'colored',
        type: 'warning',
        position: 'top-center',
        transition: 'zoom',
        autoClose: 3500,
      });
    }, 300);

    if (!store.getters["auth/isAdmin"]) {
      return next({ name: "dashboard_user" });
    }

    window.location = "/admin/dashboard";

    return next(false);
  }

  next();
});

export default router;
