import auth from "@/store/modules/auth";
import bootstrap from "@/store/modules/bootstrap";
import lang from "@/store/modules/lang";
import notifications from "@/store/modules/notifications";
import progress from "@/store/modules/progress";
import settings from "@/store/modules/settings";
import { createLogger, createStore } from "vuex";
import iso from "./modules/iso";

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    auth,
    bootstrap,
    settings,
    lang,
    progress,
    iso,
    notifications,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
