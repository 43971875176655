<template>
  <div>
    <notifications />
    <!-- / notifications -->
    <router-view />
    <!-- / routes -->
  </div>
</template>

<script>
import eventListeners from "@/mixins/eventListeners.js";
import { messages, validation } from "@/mixins/helpers";
import notification from "@/mixins/notification";
import pusher from "@/mixins/pusher";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [notification, pusher, eventListeners, messages, validation],

  computed: {
    ...mapGetters({ user: "auth/getUser", token: "auth/getRefreshToken" }),
  },

  created() {
    if (this.user) {
      this.subscribe();
    }
  },

  methods: {
    ...mapActions({
      setProgressList: "progress/setProgressList",
      fetchIsoList: "iso/fetchIsoList",
      setAccessToken: "auth/setAccessToken",
      parseAccessToken: "auth/parseAccessToken",
      failLogin: "auth/failLogin",
    }),

    subscribe() {
      // refresh token
      window.channel.callbacks.removeCallback(["_refresh-token"]);
      window.channel.bind("refresh-token", (data) => {
        if (this.user.id === data?.user_id) {
          this.refreshToken(data);
        }
      });

      // progress
      window.channel.callbacks.removeCallback(["_progress-backup"]);
      window.channel.bind("progress-backup", (data) => {
        this.setProgressList({ progressList: data });
      });

      // iso
      window.channel.callbacks.removeCallback(["_iso-complete"]);
      window.channel.bind("iso-complete", (data) => {
        if (!this.checkPermission(this.$roles.reselleruser)) {
          return false;
        }

        this.fetchIsoList();

        if ("messageKey" in data) {
          this.useShowNotification(
            this.$t(data.messageKey),
            !this.useIsEmpty(data.isoId) ? "success" : "warning"
          );

          return true;
        }
      });
    },

    refreshToken() {
      axios.get(`/refresh/token/${this.token}`)
        .then(async ({ data }) => {
          if (data?.access_token) {
            await this.setAccessToken({ token: data.access_token });
            await this.parseAccessToken({ token: data.access_token });
          }
        })
      .catch((response) => {
        this.useShowErrorNotification(response, response?.status);
        this.failLogin();
      });
    },
  },
};
</script>
