// import axios from "axios";
import i18n from "@/i18n";
import MaterialDashboard from "@/material-dashboard";
import { HalfCircleSpinner } from 'epic-spinners';
import { createApp } from "vue";
import { sync } from "vuex-router-sync";
import App from "./App.vue";
import router from "./route";
import store from "./store";

const appInstance = createApp(App);

import "./plugins";
sync(store, router);

appInstance.use(i18n);
appInstance.use(store);
appInstance.use(router);
appInstance.use(MaterialDashboard);
appInstance.component('HalfCircleSpinner', HalfCircleSpinner);
appInstance.mount("#app");
