import axios from "axios";
import swal from "sweetalert2";
import { mapGetters } from "vuex";

const swa = swal.mixin({
  customClass: {
      confirmButton: "btn btn-primary text-white",
      cancelButton: "btn btn-silver text-white",
      actions: "w-80 gc-1 flex-sm-row flex-column",
  },
  buttonsStyling: false,
});

export const mode = {
  computed: {
    ...mapGetters({ useIsDarkMode: "isDarkMode"}),

    useThClass() {
      return "text-uppercase text-xs font-weight-bolder";
    },
  },
};

export const validation = {
  methods: {
    useIsEmpty(val) {
      if (typeof val !== "undefined" && val !== null) {
        return !val || val.length === 0 || val === 0 || val === "0";
      }

      return true;
    },

    useIsEmptyObj(obj) {
      if (typeof obj !== "undefined" && obj !== null) {
        return Object.keys(obj).length === 0;
      }

      return true;
    },

    useCheckCustomerId() {
      if (typeof this.customerId !== "undefined" && this.customerId > 0) {
        return true;
      }

      return false;
    },

    useValidateEmail(email) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },

    useGetStatus: (error, val, success = null) => {
      let classes = '';

      if (typeof val === "undefined" || val === null || Object.keys(val).length === 0) {
        return '';
      }

      if (val || val === 0) {
        classes += ' is-filled ';
      }

      if (success) {
        classes += ' is-valid ';
      }

      if (error) {
        classes += ' is-invalid ';
      }

      return classes;
    },

    useGetTranslationError(name, error, params = {}) {
      params.field = this.$t(`names.${name}`);

      return this.$t(`messages.${error}`, params);
    },
  }
};

export const actions = {
  methods: {
    useConfirmDelete(fn, item) {
      if (this.useIsEmptyObj(item)) {
        return false;
      }

      swa.fire({
        title: this.$t("title.sure"),
        showCancelButton: true,
        cancelButtonText: this.$t("btn.no"),
        confirmButtonText: this.$t("btn.yes")
      }).then((result) => {
        if (
          result.value &&
          !this.useIsEmptyObj(item) &&
          typeof fn === "string"
        ) {
          this[fn](item);
        }
      });
    },

    usePerformAction(fn, item = null) {
      swa.fire({
        title: this.$t("swal.perform_action"),
        showCancelButton: true,
        cancelButtonText: this.$t("btn.no"),
        confirmButtonText: this.$t("btn.yes"),
      }).then((result) => {
        if (result.value && typeof fn === "string") {
          this[fn](item);
        }
      });
    },
  }
};

export const focus = {
  methods: {
    useFocusOnInput(id = 'editInput') {
      setTimeout(() => { document.getElementById(id)?.focus(); }, 500);
    },

    useFocusOnSelect(id = 'editInput') {
      setTimeout(() => {
          const el = document.querySelector(`#${id} [type=search]`);
          el?.focus();
        },
        500
      );
    },
  }
};

export const fix = {
  methods: {
    useToFix(val, dec = 2) {
      if (isNaN(val) || val === null) {
        return 0;
      }

      // round
      return (Math.round((+val + 0.0001) * 100) / 100).toFixed(dec);
    },

    useRoundUp(number, dec = 2) {
      return Math.ceil(number * Math.pow(10, dec)) / Math.pow(10, dec);
    },

    useRoundDown(number, dec = 2) {
      return Math.floor(number * Math.pow(10, dec)) / Math.pow(10, dec);
    },
  }
};

export const messages = {
  methods: {
    useShowErrorMessage({ data }, status = null) {
      if (status === 401) {
        return false;
      }

      if (data?.message) {
        swa.fire({ icon: "error", text: data.message });

        return false;
      }

      swa.fire({ icon: "error", text: this.$t("error.general") });
    },

    useShowErrorNotification({ data }, status = null, redirectBack = false) {
      if (status === 401) {
        return false;
      }

      if (redirectBack) {
        setTimeout(() => this.$router.back(), 3500);
      }

      if (data?.message) {
        this.useShowNotification(data.message, "error");

        return false;
      }

      if (data?.messageKey) {
        this.useShowNotification(this.$t(data.messageKey), "error");

        return false;
      }

      if (data?.error) {
        this.useShowNotification(data.error, "error");

        return false;
      }

      this.useShowNotification(this.$t("error.general"), "error");
    },

    useShowGeneralErrorMessage(status = null) {
      if (status === 401) {
        return false;
      }

      swa.fire({ icon: "error", text: this.$t("error.general") });
    },
  }
};

export const copy = {
  methods: {
    useCopyText(pass, text = "") {
      if (!pass) {
        return false;
      }

      navigator.clipboard.writeText(pass).then(() => {
        if (text) {
          this.useShowNotification(text);
        }
      });
    },
  },
};

export const breadcrumbs = {
  computed: {
    useGetBreadcrumbsUser() {
      if (this.useIsEmptyObj(this.server)) {
        return [];
      }

      const type = this.server?.type?.toLowerCase();

      switch (true) {
        case type.includes("cloud"):
          return [{ name: "cloud_server_list" }];

        case type.includes("rack"):
          return [{ name: "rack_list_user" }];

        default:
          return [{ name: "dedicated_server_list" }];
      }
    },
  },
};

export const permission = {
  methods: {
    useCheckPermission(additionalRole = null) {
      if (this.hasRole(this.$userRoles.billing)) {
        return false;
      }

      if (additionalRole && this.hasRole(additionalRole)) {
        return false;
      }

      return true;
    },
  },
};

export const convertText = {
  methods: {
    useToLower(text) {
      return text.toString().toLowerCase();
    },

    useToUpper(str) {
      return str.toString().toUpperCase();
    },

    useCapitalize(str) {
      if (typeof str === "string" && str.length > 0) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }

      return "";
    },

    useGetCurrencyPrefix(currency = "") {
      switch (currency.toLowerCase()) {
        case "eur":
          return "€";

        case "usd":
          return "$";

        default:
          return "€";
      }
    },
  },
};

export const page = {
  computed: {
    ...mapGetters({ hasRole: "auth/hasRole" }),
  },

  data() {
    return {
      pagination: {
        perPage: this.$perPage,
        currentPage: 1,
        perPageOptions: this.$perPageOptions,
        total: 0,
        from: 0,
        to: 0,
      },
      activeTab: 0,
      search: null,
    };
  },

  methods: {
    useUpdateEditingField(value, obj, key, index = null) {
      if (index !== null) {
        return (this[obj][index][key] = value);
      }

      return (this[obj][key] = value);
    },

    useGetActivePanel(index) {
      this.activeTab = index;
    },

    useGetLength(obj) {
      if (typeof obj === "object") {
        return Object.keys(obj).length;
      }

      return obj.length;
    },

    useCheckAccessDenied(error) {
      if (!this.useIsEmpty(error.code) && error.code === 403) {
        return true;
      }

      return false;
    },

    useInetAton(ip) {
      // split into octets
      let a = ip.split(".");
      let buffer = new ArrayBuffer(4);
      let dv = new DataView(buffer);

      for (let i = 0; i < 4; i++) {
        dv.setUint8(i, a[i]);
      }

      return dv.getUint32(0);
    },

    useGetFistElementOfObject(obj) {
      if (typeof obj === "object") {
        return obj[Object.keys(obj)[0]];
      }

      return null;
    },
  },
};

export const rack = {
  methods: {
    debounceSearch(searchTerm, inputLoading) {
      this.useSimpleDebounce(() => {
        this.getFoundIPs(searchTerm, inputLoading);
      }, this.$debounceTimeJS);
    },

    getFoundIPs(searchTerm, inputLoading) {
      let formData = new FormData();
      formData.append("rackid", this.id);
      formData.append("query", searchTerm);

      axios
        .post("/ip4/search", formData)
        .then(({ data }) => {
          inputLoading(false);

          if (!this.useIsEmptyObj(data)) {
            return (this.ip4Addresses = data);
          }

          this.ip4Addresses = [];
        })
        .catch(({ response }) => {
          inputLoading(false);
          this.ip4Addresses = [];
          this.useShowErrorNotification(response, response?.status);
        });
    },
  },
};

export const blesta = {
  data() {
    return { blestaError: false };
  },

  methods: {
    useShowBlestaErrors({ errors }) {
      if (!this.useIsEmptyObj(errors)) {
        for (const error of Object.values(errors)) {
          for (const message of Object.values(error)) {
            this.useShowNotification(message, "error");
          }
        }

        return (this.blestaError = true);
      }

      return (this.blestaError = false);
    },
  },
};
