import GlobalVariablesRoles from "@/globalVariablesRoles.js";
import { createApp } from "vue";
const app = createApp({});
app.use(GlobalVariablesRoles);

const global = app.config.globalProperties;

const DashboardLayout = () => import("@/bundles/user/views/layout/DashboardLayout.vue");
// Auth views
const Login = () => import("@/pages/Login.vue");
const RestoreAccount = () => import("@/pages/RestoreAccount.vue");
// Dashboard views
const Dashboard = () => import("../views/dashboard/Dashboard.vue");
// Client views
const Account = () => import("@/views/Account.vue");
// Cloud Server views
const CreateCloudUser = () => import("@/views/cloud/CreateCloud.vue");
const CloudServerList = () => import("../views/services/CloudList.vue");
const CloudStatistic = () => import("../views/services/CloudStatistic.vue");
const ChangeSettings = () => import("../views/services/service/ChangeSettings.vue");
// Dedicated Server views
const DedicatedServerList = () => import("../views/services/DedicatedList.vue");
// Racks views
const RacksList = () => import("../views/services/RacksList.vue");
// Firewall view
const FirewallRules = () => import("@/views/cloud/FirewallRules.vue");
// Template views
const TemplateList = () => import("../views/template/List.vue");
// ISO views
const IsoList = () => import("../views/iso/List.vue");
// IP views
const IPAddressesServer = () => import("../views/services/service/IpAddresses.vue");
// Traffic views
const ServerTraffic = () => import("../views/services/service/ServerTraffic.vue");
const TrafficNotificationList = () => import("../views/services/service/TrafficNotificationList.vue");
// Backup views
const BackupList = () => import("../views/backup/List.vue");
const SnapshotList = () => import("../views/snapshot/List.vue");
// Service list
const ServiceList = () => import("../views/billing/ServiceList.vue");
// Cloud agreements
const CloudAgreementsDetail = () => import("@/views/cloud/CloudAgreementsDetail.vue");
// Invoice views
const InvoiceList = () => import("../views/billing/InvoiceList.vue");
// User views
const StoreContact = () => import("../views/user/StoreContact.vue");
const ContactList = () => import("../views/user/ContactList.vue");
const StoreReseller = () => import("../views/user/StoreReseller.vue");
const ResellerList = () => import("../views/user/ResellerList.vue");
const ResellerOfferList = () => import("../views/user/ResellerOfferList.vue");
// Order view
const CreateOrder = () => import("@/views/CreateOrder.vue");
// Maintenance mode view
const Maintenance = () => import("@/pages/Maintenance.vue");
// API documentation
const ApiDocumentation = () => import("../views/docs/ApiDoc.vue");
// Help view
const HelpPage = () => import("../views/docs/HelpPage.vue");
// Error view
const PageError = () => import("@/pages/Error.vue");
const PageNotFound = () => import("@/views/PageNotFound.vue");

const userPages = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "dashboard",
      name: "dashboard_user",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user, global.$roles.reselleruser],
      },
      component: Dashboard,
    },
    {
      path: "account",
      name: "profile_user",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user, global.$roles.reselleruser],
        additionalRole: [global.$userRoles.admin, global.$userRoles.billing],
      },
      component: Account,
    },
    {
      path: "doc",
      component: ApiDocumentation,
      name: "api_doc",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        access: ["isMainUserAccount", "allowApiAccess"],
      },
    },
    {
      path: "server/dedicated/list",
      name: "dedicated_server_list",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user, global.$roles.reselleruser],
        access: ["hasDedicatedServer"],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
      },
      component: DedicatedServerList,
    },
    {
      path: "racks",
      name: "rack_list_user",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
      },
      component: RacksList,
    },
    {
      path: "server/cloud/list",
      name: "cloud_server_list",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user, global.$roles.reselleruser],
        access: ["showCloudMenu"],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
      },
      component: CloudServerList,
    },
    {
      path: "server/cloud/add",
      name: "create_cloud_server_user",
      meta: {
        requiresAuth: true,
        role: global.$roles.user,
        access: ["hasCloudOptions"],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
      },
      component: CreateCloudUser,
    },
    {
      path: "cloud/templates",
      name: "template_cloud_list",
      meta: {
        requiresAuth: true,
        role: global.$roles.user,
        access: ["showCloudMenu", "hasCloudOptions"],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
      },
      component: TemplateList,
    },
    {
      path: "cloud/iso/list",
      name: "iso_cloud_list",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        access: ["showCloudMenu", "showIsoManager"],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
      },
      component: IsoList,
    },
    {
      path: "cloud/statistic",
      name: "cloud_statistic_user",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
        access: ["showCloudMenu", "hasCloudOptions"],
      },
      component: CloudStatistic,
    },
    {
      path: "server/cloud/:id/firewall",
      name: "cloud_firewall_detail",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user, global.$roles.reselleruser],
        access: ["hasCloudOptions", "allowFirewall"],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
      },
      component: FirewallRules,
    },
    {
      path: "server/:id/ip-ptr",
      name: "ip_addresses_of_server_detail",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user, global.$roles.reselleruser],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
      },
      component: IPAddressesServer,
    },
    {
      path: "server/:id/traffic",
      name: "traffic_of_server_detail",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user, global.$roles.reselleruser],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
      },
      component: ServerTraffic,
    },
    {
      path: "server/:server_id/traffic/notification-rules",
      name: "traffic_notification_list",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user, global.$roles.reselleruser],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
        access: ["allowTrafficNotification"],
      },
      component: TrafficNotificationList,
    },
    {
      path: "/server/cloud/:id/backups",
      name: "backup_list_of_cloud_detail",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user, global.$roles.reselleruser],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
      },
      component: BackupList,
    },
    {
      path: "/server/cloud/:id/snapshots",
      name: "snapshot_list_of_cloud_detail",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user, global.$roles.reselleruser],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
      },
      component: SnapshotList,
    },
    {
      path: "/server/cloud/:id/change-definition",
      name: "change_settings_of_cloud_server",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user, global.$roles.reselleruser],
        additionalRole: [global.$userRoles.admin, global.$userRoles.tech],
      },
      component: ChangeSettings,
    },
    {
      path: "/services",
      name: "service_list",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        access: ["isMainUserAccount"],
        blesta: true,
      },
      component: ServiceList,
    },
    {
      path: "/cloud/agreements",
      name: "cloud_agreements",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        access: ["isMainUserAccount"],
        blesta: true,
      },
      component: CloudAgreementsDetail,
    },
    {
      path: "/invoice/list",
      name: "invoice_list",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        access: ["isMainUserAccount"],
        blesta: true,
      },
      component: InvoiceList,
    },
    {
      path: "user/add",
      name: "add_new_user",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        additionalRole: false,
      },
      component: StoreContact,
    },
    {
      path: "user/:id/edit",
      name: "edit_normal_user",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        additionalRole: false,
      },
      component: StoreContact,
    },
    {
      path: "/user/list",
      name: "user_normal_list",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        additionalRole: [global.$userRoles.admin],
      },
      component: ContactList,
    },
    {
      path: "reseller/add",
      name: "add_new_reseller_user",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        access: ["showResellerManagement"],
        additionalRole: [global.$userRoles.admin],
      },
      component: StoreReseller,
    },
    {
      path: "reseller/:id/edit",
      name: "edit_reseller_user",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        access: ["showResellerManagement"],
        additionalRole: [global.$userRoles.admin],
      },
      component: StoreReseller,
    },
    {
      path: "reseller/list",
      name: "user_reseller_list",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        access: ["showResellerManagement"],
        additionalRole: [global.$userRoles.admin],
      },
      component: ResellerList,
    },
    {
      path: "reseller/offer",
      name: "reseller_offer_list",
      meta: {
        requiresAuth: true,
        role: [global.$roles.user],
        access: ["showResellerManagement"],
        additionalRole: [global.$userRoles.admin],
      },
      component: ResellerOfferList,
    },
    {
      path: "/help",
      name: "user_help",
      meta: {
        requiresAuth: true,
        access: ["hasHelpPage"],
        role: [global.$roles.user, global.$roles.reselleruser],
      },
      component: HelpPage,
    },
    {
      path: "/:pathMatch(.*)*",
      name: "user_page_not_found",
      meta: { showErrorBg: true, requiresAuth: true },
      component: PageNotFound,
    },
  ],
};

let orderPages = {
  path: "/order/",
  name: "Order",
  children: [
    {
      path: "server-housing",
      name: "server-housing",
      meta: { blesta: true },
      component: CreateOrder,
    },
    {
      path: "half-rack",
      name: "half-rack",
      meta: { blesta: true },
      component: CreateOrder,
    },
    {
      path: "full-rack",
      name: "full-rack",
      meta: { blesta: true },
      component: CreateOrder,
    },
    {
      path: "rootserver-r440",
      name: "rootserver-r440",
      meta: { blesta: true },
      component: CreateOrder,
    },
    {
      path: "rootserver-r640",
      name: "rootserver-r640",
      meta: { blesta: true },
      component: CreateOrder,
    },
    {
      path: "managed-server",
      name: "managed-server",
      meta: { blesta: true },
      component: CreateOrder,
    },
    {
      path: "managed-r440",
      name: "managed-r440",
      meta: { blesta: true },
      component: CreateOrder,
    },
    {
      path: "managed-r640",
      name: "managed-r640",
      meta: { blesta: true },
      component: CreateOrder,
    },
    {
      path: "ha-server",
      name: "ha-server",
      meta: { blesta: true },
      component: CreateOrder,
    },
    {
      path: "ha-r440",
      name: "ha-r440",
      meta: { blesta: true },
      component: CreateOrder,
    },
    {
      path: "ha-r640",
      name: "ha-r640",
      meta: { blesta: true },
      component: CreateOrder,
    },
    {
      path: "cloud-server",
      name: "cloud-server",
      meta: { blesta: true },
      component: CreateOrder,
    },
  ],
};

const routes = [
  { path: "/", name: "/", redirect: { name: "login" } },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: Maintenance,
  },
  {
    path: "/error",
    name: "user_page_error",
    component: PageError,
  },
  {
    path: "/restore-account/:token/:hash",
    name: "Restore Account",
    component: RestoreAccount,
  },
  userPages,
  orderPages,
];

export default routes;
