const state = {
  progressList: {},
};

const getters = {
  getProgress: (state) => (id) =>
    state.progressList[id]?.progress ||
    JSON.stringify(localStorage.getItem("progress"))[id]?.progress,
  getProgressList: (state) => state.progressList,
  hasTask: (state) => (id) =>
    state.progressList &&
    Object.keys(state.progressList).some((item) => +item === id),
  getTaskType: (state) => (id) => state.progressList[id]?.type || "backup",
};

const mutations = {
  /**
   * Set progress
   * @param {*} state
   * @param {*} progressList
   */
  setProgressList(state, { progressList }) {
    state.progressList = { ...state.progressList, ...progressList };
    localStorage.setItem("progress", JSON.stringify(state.progressList));
  },

  /**
   * Delete progress
   * @param {*} state
   * @param {*} id
   */
  deleteProgress(state, { id }) {
    delete state.progressList[id];
    localStorage.setItem("progress", JSON.stringify(state.progressList));
  },
};

const actions = {
  /**
   * Set progress
   * @param {*} commit
   * @param {*} progressList
   */
  setProgressList({ commit }, { progressList }) {
    commit("setProgressList", { progressList });
  },

  /**
   * Delete progress
   * @param {*} commit
   * @param {*} id
   */
  deleteProgress({ commit }, { id }) {
    commit("deleteProgress", { id });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
