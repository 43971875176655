import axios from "axios";
import store from "../store";

// Request interceptor
axios.interceptors.request.use((config) => {
  const accessToken = store.getters["auth/getAccessToken"];

  config.baseURL = "/api";
  // config.headers["Content-Type"] = "application/json";

  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    config.headers["X-Refresh-Token"] = store.getters["auth/getRefreshToken"];
  }

  const locale = store.getters["lang/getLang"];

  if (locale) {
    config.headers["Accept-Language"] = locale;
  }

  return config;
});
